<script setup>
import {
    Edit,
    Delete
} from '@element-plus/icons-vue'
import { ref } from 'vue'

//分页条数据模型
const pageNum = ref(1)//当前页
const total = ref(20)//总条数
const pageSize = ref(3)//每页条数

//当每页条数发生了变化，调用此函数
const onSizeChange = (size) => {
    pageSize.value = size
    cksLists()
}
//当前页码发生变化，调用此函数
const onCurrentChange = (num) => {
    pageNum.value = num
    cksLists()
}

const cks = ref([
    {
        "id": "1",
        "cname":"软件工程实践VI", 
        "kmajor":"软件工程", 
        "kbj":"软件212", 
        "ksno":202104,
        "kname":"代佳兰",
        "ksname":"志愿者服务管理" ,
        "result":"通过",
        "score":89
    },
    {
        "id": "2",
        "cname": "WEB应用系统开发",
        "kmajor":"软件工程",
        "kbj": "软件212",
        "ksno":202110,
        "kname":"郭元琳",
        "ksname":"毕业生就业管理",
        "result":"通过",
        "score":89
    },
    {
        "id": "3",
        "cname": "人机交互与界面设计",
        "kmajor":"软件工程",
        "kbj": "软件212",
        "ksno":202132,
        "kname":"王彩娣",
        "ksname":"班级网站管理",
        "result":"通过",
        "score":89
    },
    {
        "id": "4",
        "cname":"软件工程实践VI", 
        "kmajor":"软件工程", 
        "kbj":"软件212", 
        "ksno":202142,
        "kname":"张姣姣",
        "ksname":"高考填报志愿分析管理" ,
        "result":"通过",
        "score":90
    }
])
//用户搜索时选中的id
const id = ref('')
//用户搜索时选中的发布状态
const ksname = ref('')
//声明一个异步的函数
import { cksListService, AddCksService, UpdateCksService, DeleteCksService, cksListServices} from '@/api/cks.js'
//获取列表数据
const cksList = async () => {
    let result = await cksListService();
    cks.value = result.data;
}
const cksLists = async () => {
    let params = {
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        id: id.value ? id.value : null,
        ksname: ksname.value ? ksname.value : null
    }
    let result = await cksListServices(params);
    //渲染视图
    total.value = result.data.total;
    cks.value = result.data.items;

}
//调用
cksList();
//分页
cksLists();
//控制添加弹窗
const dialogVisible = ref(false)
//控制抽屉是否显示
const visibleDrawer = ref(false)

// //添加数据模型
const cksModel = ref({
    id: "",
    cname: "",
    kmajor: "",
    kbj: "",
    ksno: "",
    kname: "",
    ksname: "",
    result: "",
    score: ""
})


//添加表单校验
const rules = {
    id: [
        { required: true, message: 'ID', trigger: 'blur' },
    ],
    cname: [
        { required: true, message: '课程名', trigger: 'blur' },
    ],
    kmajor: [
        { required: true, message: '专业', trigger: 'blur' },
    ],
    kbj: [
        { required: true, message: '班级', trigger: 'blur' },
    ],
    ksno: [
        { required: true, message: '学号', trigger: 'blur' },
    ],
    kname: [
        { required: true, message: '学生姓名', trigger: 'blur' },
    ],
    ksname: [
        { required: true, message: '课设题目', trigger: 'blur' },
    ],
    result: [
        { required: true, message: '选题结果', trigger: 'blur' },
    ],
    score: [
        { required: true, message: '课设成绩', trigger: 'blur' },
    ]
}


// //调用接口,添加表单
import { ElMessage } from 'element-plus'
const addCks = async () => {
    //调用接口
    let result = await AddCksService(cksModel.value);
    ElMessage.success(result.msg ? result.msg : '添加成功')

    //调用获取所有分类的函数
    cksList();
    dialogVisible.value = false;
}

// //定义变量,控制标题的展示
const title = ref('')

// //展示编辑弹窗
const showDialog = (row) => {
    dialogVisible.value = true; title.value = '编辑'
    //数据拷贝
    cksModel.value.id = row.id;
    cksModel.value.cname = row.cname;
    cksModel.value.kmajor = row.kmajor;
    cksModel.value.kbj = row.kbj;
    cksModel.value.ksno = row.ksno;
    cksModel.value.kname = row.kname;
    cksModel.value.ksname = row.ksname;
    cksModel.value.result = row.result;
    cksModel.value.score = row.score;
    //扩展id属性,将来需要传递给后台,完成分类的修改
    // classModel.value.id = row.id
}

// //编辑课程
const updateCks = async () => {
    //调用接口
    let result = await UpdateCksService(cksModel.value);

    ElMessage.success(result.msg ? result.msg : '修改成功')

    //调用获取所有课程的函数
    cksList();

    //隐藏弹窗
    dialogVisible.value = false;
}

//清空模型的数据
const clearData = () => {
    cksModel.value.id = "";
    cksModel.value.cname = "";
    cksModel.value.kmajor = "";
    cksModel.value.kbj = "";
    cksModel.value.ksno = "";
    cksModel.value.kname = "";
    cksModel.value.ksname = "";
    cksModel.value.result = "";
    cksModel.value.score = "";
}

//删除
import {ElMessageBox} from 'element-plus'
const deleteCks = (row) => {
    //提示用户  确认框

    ElMessageBox.confirm(
        '你确认要删除该信息吗?',
        '温馨提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            //调用接口
            let result = await DeleteCksService(row.id);
            ElMessage({
                type: 'success',
                message: '删除成功',
            })
            //刷新列表
            cksList();
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '用户取消了删除',
            })
        })
}
</script>
<template>
    <el-card class="page-container">
        <template #header>
            <div class="header">
                <span>课程课设列表</span>
                <div class="extra">
                    <el-button type="primary">添加</el-button>
                </div>
            </div>
        </template>
        <el-table :data="cks" style="width: 100%">
            <el-table-column label="课程ID" prop="id" width="100"></el-table-column>
            <el-table-column label="课程名" prop="cname"></el-table-column>
            <el-table-column label="专业" prop="kmajor"></el-table-column>
            <el-table-column label="班级" prop="kbj"></el-table-column>
            <el-table-column label="学号" prop="ksno"> </el-table-column>
            <el-table-column label="姓名" prop="kname"></el-table-column>
            <el-table-column label="课设题目" prop="ksname"></el-table-column>
            <el-table-column label="选题结果" prop="result"></el-table-column>
            <el-table-column label="成绩" prop="score"></el-table-column>
            <el-table-column label="操作" width="100">
                <template #default="{ row }">
                    <el-button :icon="Edit" circle plain type="primary"></el-button>
                    <el-button :icon="Delete" circle plain type="danger"></el-button>
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="没有数据" />
            </template>
        </el-table>
        <!-- 分页条 -->
        <el-pagination v-model:current-page="pageNum" v-model:page-size="pageSize" :page-sizes="[2, 3, 5, 7]"
            layout="jumper, total, sizes, prev, pager, next" background :total="total" @size-change="onSizeChange"
            @current-change="onCurrentChange" style="margin-top: 20px; justify-content: flex-end" />

        <!-- 添加弹窗 -->
        <el-dialog v-model="dialogVisible" :title="title" width="30%">
            <el-form :model="cksModel" :rules="rules" label-width="100px" style="padding-right: 30px">
                <el-form-item label="课程ID" prop="id">
                    <el-input v-model="cksModel.id" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="课程名" prop="cname">
                    <el-input v-model="cksModel.cname" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="专业" prop="kmajor">
                    <el-input v-model="cksModel.kmajor" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="班级" prop="kbj">
                    <el-input v-model="cksModel.kbj" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="学号" prop="ksno">
                    <el-input v-model="cksModel.ksno" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="kname">
                    <el-input v-model="cksModel.kname" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="课设题目" prop="ksname">
                    <el-input v-model="cksModel.ksname" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="选题结果" prop="result">
                    <el-input v-model="cksModel.result" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="成绩" prop="score">
                    <el-input v-model="cksModel.score" minlength="1" maxlength="15"></el-input>
                </el-form-item>
               
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary"> 确认 </el-button>
                </span>
            </template>
        </el-dialog>
    </el-card>
</template>

<style lang="scss" scoped>
.page-container {
    min-height: 100%;
    box-sizing: border-box;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>