<script setup>
import {
    Edit,
    Delete
} from '@element-plus/icons-vue'
import { ref } from 'vue'
const pks = ref([
    {
        "sno":202132,
        "sname": "王彩娣",
        "cname":"女",
        "ksname": "软件工程",
        "score":"软件212",
        "term":"2022-02-01"
    }
])
//声明一个异步的函数
import { pksListService, AddPksService, UpdatePksService, DeletePksService } from '@/api/pks.js'
const pksList = async () => {
    let result = await pksListService();
    pks.value = result.data;
}
//调用
pksList();
//控制添加弹窗
const dialogVisible = ref(false)

// //添加数据模型
const pksModel = ref({
    
})
// //添加表单校验
const rules = {
    sno: [
        { required: true, message: '学号', trigger: 'blur' },
    ],
    sname: [
        { required: true, message: '学生姓名', trigger: 'blur' },
    ],
    cname: [
        { required: true, message: '课程名', trigger: 'blur' },
    ],
    ksname: [
        { required: true, message: '课设题目', trigger: 'blur' },
    ],
    score: [
        { required: true, message: '成绩', trigger: 'blur' },
    ],
    term: [
        { required: true, message: '学期', trigger: 'blur' },
    ]
}


// //调用接口,添加表单
import { ElMessage } from 'element-plus'
const addPks = async () => {
    //调用接口
    let result = await AddPksService(pksModel.value);
    ElMessage.success(result.msg ? result.msg : '添加成功')

    //调用获取所有分类的函数
    pksList();
    dialogVisible.value = false;
}

// //定义变量,控制标题的展示
const title = ref('')

// //展示编辑弹窗
const showDialog = (row) => {
    dialogVisible.value = true; title.value = '编辑'
    //数据拷贝
    pksModel.value.sno = row.sno;
    pksModel.value.sname = row.sname;
    pksModel.value.cname = row.cname;
    pksModel.value.ksname = row.ksname;
    pksModel.value.score = row.score;
    pksModel.value.term = row.term;
    //扩展id属性,将来需要传递给后台,完成分类的修改
    // classModel.value.id = row.id
}

// //编辑课程
const updatePks = async () => {
    //调用接口
    let result = await UpdatePksService(pksModel.value);

    ElMessage.success(result.msg ? result.msg : '修改成功')

    //调用获取所有课程的函数
    pksList();

    //隐藏弹窗
    dialogVisible.value = false;
}

//清空模型的数据
const clearData = () => {
    pksModel.value.sno = "";
    pksModel.value.sname = "";
    pksModel.value.cname = "";
    pksModel.value.ksname = "";
    pksModel.value.score = "";
    pksModel.value.term = "";
}

// //删除
import {ElMessageBox} from 'element-plus'
const deletePks = (row) => {
//     //提示用户  确认框

    ElMessageBox.confirm(
        '你确认要删除该班级信息吗?',
        '温馨提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            //调用接口
            let result = await DeletePksService(row.sno);
            ElMessage({
                type: 'success',
                message: '删除成功',
            })
            //刷新列表
            pksList();
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '用户取消了删除',
            })
        })
}
</script>
<template>
    <el-card class="page-container">
        <template #header>
            <div class="header">
                <span>学生个人课设列表</span>
                <div class="extra">
                    <el-button type="primary" @click="dialogVisible = true;title='添加';clearData()">添加</el-button>
                </div>
            </div>
        </template>
        <el-table :data="pks" style="width: 100%">
            <el-table-column label="学号" width="100" prop="sno"> </el-table-column>
            <el-table-column label="姓名" prop="sname"></el-table-column>
            <el-table-column label="课程名" prop="cname"></el-table-column>
            <el-table-column label="课设题目" prop="ksname"></el-table-column>
            <el-table-column label="成绩" prop="score"></el-table-column>
            <el-table-column label="学期" prop="term"></el-table-column>
            <el-table-column label="操作" width="100">
                <template #default="{ row }">
                    <el-button :icon="Edit" circle plain type="primary" @click="showDialog(row)"></el-button>
                    <el-button :icon="Delete" circle plain type="danger" @click="deletePks(row)"></el-button>
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="没有数据" />
            </template>
        </el-table>

        <!-- 添加弹窗 -->
        <el-dialog v-model="dialogVisible" :title="title" width="30%">
            <el-form :model="studentModel" :rules="rules" label-width="100px" style="padding-right: 30px">
                <el-form-item label="学号" prop="sno">
                    <el-input v-model="pksModel.sno" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="sname">
                    <el-input v-model="pksModel.sname" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="课程名" prop="cname">
                    <el-input v-model="pksModel.cname" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="课设题目" prop="ksname">
                    <el-input v-model="pksModel.ksname" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="成绩" prop="score">
                    <el-input v-model="pksModel.score" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="学期" prop="term">
                    <el-input v-model="pksModel.term" minlength="1" maxlength="15"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="title=='添加' ? addPks() : updatePks()"> 确认 </el-button>
                </span>
            </template>
        </el-dialog>
    </el-card>
</template>

<style lang="scss" scoped>
.page-container {
    min-height: 100%;
    box-sizing: border-box;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>