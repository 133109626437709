import request from '@/utils/request.js'
import { useTokenStore } from '@/stores/token.js'
//课程列表信息
export const pksListService = ()=>{
    //const tokenStore = useTokenStore();
    //在pinia中定义的响应式数据，都不需要value
    //return request.get("/course",{headers:{'Authorization':tokenStore.token}})
    return request.get("/pks")
}
//添加信息
export const AddPksService = (pksData)=>{
    return request.post('/pks',pksData)
}
//修改信息
export const UpdatePksService = (pksData)=>{
    return request.put('/pks/update',pksData)
}
//删除信息
export const DeletePksService = (sno)=>{
    return request.delete('/pks?sno='+sno)
}