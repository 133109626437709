<script setup>
import { User, Lock } from '@element-plus/icons-vue'
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
//控制注册与登录表单的显示， 默认显示注册
const isRegister = ref(false)
//定义数据模型
const registerData = ref({
  username: '',
  password: '',
  role:'',
  picture:'',
  rePassword: ''
})

//校验密码的函数
const checkRePassword = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次确认密码'))
  } else if (value !== registerData.value.password) {
    callback(new Error('请确保两次输入的密码一样'))
  } else {
    callback()
  }
}

//定义表单校验规则
const rules = {
  username: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
    { min: 5, max: 16, message: '长度为5~16位非空字符', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 5, max: 16, message: '长度为5~16位非空字符', trigger: 'blur' }
  ],
  rePassword: [
    { validator: checkRePassword, trigger: 'blur' }
  ],
  role: [
    { required: true, message: '请选择角色', trigger: 'blur' },
  ],
}

//调用后台接口,完成注册
import { userRegisterService, userLoginService,userByUsernameService} from '@/api/user.js'
const register = async () => {
  //registerData是一个响应式对象,如果要获取值,需要.value
  let result = await userRegisterService(registerData.value);
  ElMessage.success(result.msg ? result.msg : '注册成功')
}
//绑定数据,复用注册表单的数据模型
//表单数据校验
//登录函数
const role = ref();
import {useTokenStore} from '@/stores/token.js'
import {useRouter} from 'vue-router'
const router = useRouter()
const tokenStore = useTokenStore();
const user = ref();
const login = async ()=> {
  let result = await userByUsernameService(registerData.value.username);
  user.value =result.data

  if(user.value.role=="管理员")
  {
    if(registerData.value.role=="管理员")
    {
        //调用接口,完成登录
        let result = await userLoginService(registerData.value);
        ElMessage.success(result.msg ? result.msg : '登录成功')
        //把得到的token存储到pinia中
        tokenStore.setToken(result.data)
      //跳转到首页 路由完成跳转
        router.push('/')
    }
    else
    {
      ElMessage.error("当前登录用户身份不匹配")
    }
  }
  else if(user.value.role=="学生")
  {
    if(registerData.value.role=="学生")
    {
        //调用接口,完成登录
        let result = await userLoginService(registerData.value);
        ElMessage.success(result.msg ? result.msg : '登录成功')
        //把得到的token存储到pinia中
        tokenStore.setToken(result.data)
      //跳转到首页 路由完成跳转
        router.push('/stu')
    }
    else
    {
      ElMessage.error("当前登录用户身份不匹配")
    }
  }
  else if(user.value.role=="老师")
  {
    if(registerData.value.role=="老师")
      {
          //调用接口,完成登录
          let result = await userLoginService(registerData.value);
          ElMessage.success(result.msg ? result.msg : '登录成功')
          //把得到的token存储到pinia中
          tokenStore.setToken(result.data)
        //跳转到首页 路由完成跳转
          router.push('/tea')
      }
      else
      {
        ElMessage.error("当前登录用户身份不匹配")
      }
    }
}
//定义函数,清空数据模型的数据
  const clearRegisterData = () => {
    registerData.value = {
      username: '',
      password: '',
      picture:'',
      rePassword: ''
    }
  }
</script>
<template>
  <el-row class="login-page">
    <el-col :span="12" class="bg"></el-col>
    <el-col :span="6" :offset="3" class="form">
      <!-- 注册表单 -->
      <el-form ref="form" size="large" autocomplete="off" v-if="isRegister" :model="registerData" :rules="rules">
        <el-form-item>
          <h1>注册</h1>
        </el-form-item>
        <el-form-item prop="username">
          <el-input :prefix-icon="User" placeholder="请输入用户名" v-model="registerData.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input :prefix-icon="Lock" type="password" placeholder="请输入密码" v-model="registerData.password"></el-input>
        </el-form-item>
        <el-form-item prop="rePassword">
          <el-input :prefix-icon="Lock" type="password" placeholder="请输入再次密码" v-model="registerData.rePassword"></el-input>
        </el-form-item>
        <!-- 注册按钮 -->
        <el-form-item>
          <el-button class="button" type="primary" auto-insert-space @click="register">
            注册
          </el-button>
        </el-form-item>
        <el-form-item prop="role">
        <el-radio-group v-model="registerData.role">
          <el-radio label="管理员"></el-radio>
          <el-radio label="学生"></el-radio>
          <el-radio label="老师"></el-radio>
        </el-radio-group>
        </el-form-item>
        <el-form-item class="flex">
          <el-link type="info" :underline="false" @click="isRegister = false;clearRegisterData()">
            ← 返回
          </el-link>
        </el-form-item>
      </el-form>
      <!-- 登录表单 -->
      <el-form ref="form" size="large" autocomplete="off" v-else>
        <el-form-item>
          <h2>课程设计信息管理系统<h1>登录</h1></h2>
        </el-form-item>
        <el-form-item prop="username">
          <el-input :prefix-icon="User" placeholder="请输入用户名"
          v-model="registerData.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input name="password" :prefix-icon="Lock" type="password" placeholder="请输入密码"
          v-model="registerData.password"></el-input>
        </el-form-item>
        <el-form-item prop="role">
        <el-radio-group v-model="registerData.role">
          <el-radio label="管理员"></el-radio>
          <el-radio label="学生"></el-radio>
          <el-radio label="老师"></el-radio>
        </el-radio-group>
        </el-form-item>
        <el-form-item class="flex">
          <div class="flex">
            <el-checkbox>记住我</el-checkbox>
            <el-link type="primary" :underline="false">忘记密码？</el-link>
          </div>
        </el-form-item>
        <!-- 登录按钮 -->
        <el-form-item>
          <el-button class="button" type="primary" auto-insert-space @click="login">登录</el-button>
        </el-form-item>
        <el-form-item class="flex">
          <el-link type="info" :underline="false" @click="isRegister = true;clearRegisterData()">
            注册 →
          </el-link>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<style lang="scss" scoped>
/* 样式 */
.login-page {
  height: 100vh;
  background-color: #fff;

  .bg {
  //background: url('@/assets/img.png') no-repeat 60% center / 240px auto,
  background: url('@/assets/xin.png') no-repeat  center / cover;
  border-radius: 20px 20px 20px 20px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
  .title {
  margin: 0 auto;
  }
  .button {
  width: 100%;
  }
  .flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
}
</style>