<script setup>
import {
    Edit,
    Delete
} from '@element-plus/icons-vue'
import { ref } from 'vue'
const students = ref([
    {
        "sno":202110,
        "sname": "郭元琳",
        "sex":"女",
        "smajor": "软件工程",
        "sclass":"软件212"
    },
    {
        "sno":202104,
        "sname": "代佳兰",
        "sex":"女",
        "smajor": "软件工程",
        "sclass":"软件212"
    },
    {
        "sno":202142,
        "sname": "张姣姣",
        "sex":"女",
        "smajor": "软件工程",
        "sclass":"软件212"
    },
    {
        "sno":202106,
        "sname": "方秋宇",
        "sex":"女",
        "smajor": "软件工程",
        "sclass":"软件212"
    },
    {
        "sno":202132,
        "sname": "王彩娣",
        "sex":"女",
        "smajor": "软件工程",
        "sclass":"软件212"
    }
])
//分页条数据模型
const pageNum = ref(1)//当前页
const total = ref(7)//总条数
const pageSize = ref(3)//每页条数

//当每页条数发生了变化，调用此函数
const onSizeChange = (size) => {
    pageSize.value = size
    stuLists()
}
//当前页码发生变化，调用此函数
const onCurrentChange = (num) => {
    pageNum.value = num
    stuLists()
}
//声明一个异步的函数
import { studentsListService, AddStudentsService,UpdateStudentsService,DeleteStudentsService,studentService } from '@/api/students.js'
const studentList = async () => {
    let result = await studentsListService();
    students.value = result.data;
}
//用户搜索时选中的
const smajor = ref('')
//用户搜索时选中的
const sname = ref('')

const stuLists = async () => {
    let params = {
        pageNum: pageNum.value,
        pageSize: pageSize.value,
        smajor: smajor.value ? smajor.value : null,
        sname: sname.value ? sname.value : null
    }
    let result = await studentService(params);
    //渲染视图
    total.value = result.data.total;
    students.value = result.data.items;

}
//调用
studentList();
//分页
stuLists();

//控制添加弹窗
const dialogVisible = ref(false)

// //添加数据模型
const studentModel = ref({
    "sno": "",
    "sname": "",
    "sex": "",
    "smajor": "",
    "sclass": ""
})
// //添加表单校验
const rules = {
    sno: [
        { required: true, message: '请输入学号', trigger: 'blur' },
    ],
    sname: [
        { required: true, message: '请输入姓名', trigger: 'blur' },
    ],
    sex: [
        { required: true, message: '性别', trigger: 'blur' },
    ],
    smajor: [
        { required: true, message: '专业', trigger: 'blur' },
    ],
    sclass: [
        { required: true, message: '班级', trigger: 'blur' },
    ],
}

// //调用接口,添加表单
import { ElMessage } from 'element-plus'
const addStudent = async () => {
    //调用接口
    let result = await AddStudentsService(studentModel.value);
    ElMessage.success(result.msg ? result.msg : '添加成功')

    //调用获取所有分类的函数
    studentList();
    dialogVisible.value = false;
}

// //定义变量,控制标题的展示
const title = ref('')

// //展示编辑弹窗
const showDialog = (row) => {
    dialogVisible.value = true; title.value = '编辑'
    //数据拷贝
    studentModel.value.sno = row.sno;
    studentModel.value.sname = row.sname;
    studentModel.value.sex = row.sex;
    studentModel.value.smajor = row.smajor;
    studentModel.value.sclass = row.sclass;
    //扩展id属性,将来需要传递给后台,完成分类的修改
    // classModel.value.id = row.id
}

// //编辑课程
const updateStudent = async () => {
    //调用接口
    let result = await UpdateStudentsService(studentModel.value);

    ElMessage.success(result.msg ? result.msg : '修改成功')

    //调用获取所有课程的函数
    studentList();

    //隐藏弹窗
    dialogVisible.value = false;
}

//清空模型的数据
const clearData = () => {
    studentModel.value.sno = '';
    studentModel.value.sname = '';
    studentModel.value.sex = '';
    studentModel.value.smajor = '';
    studentModel.value.sclass = '';
}

// //删除
import {ElMessageBox} from 'element-plus'
const deleteStudent = (row) => {
//     //提示用户  确认框

    ElMessageBox.confirm(
        '你确认要删除该学生信息吗?',
        '温馨提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            //调用接口
            let result = await DeleteStudentsService(row.sno);
            ElMessage({
                type: 'success',
                message: '删除成功',
            })
            //刷新列表
            studentList();
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '用户取消了删除',
            })
        })
}
const file = ref(null); // 用户选择的文件
    const uploadMessage = ref(null); // 上传结果的消息

    // 处理文件选择框变化的事件
    const handleFileChange = event => {
      file.value = event.target.files[0];
    };

    // 上传文件的方法
    const uploadFile = async() => {
      if (!file.value) {
        uploadMessage.value = '请先选择一个文件。';
        return;
      }
      try {
        const response = await fileService(file.value);
        uploadMessage.value = response.data.message || '文件上传成功！';
      } catch (error) {
        console.error('文件上传失败:', error);
        uploadMessage.value = '文件上传失败，请重试。';
      } finally {
        // 重置文件状态
        file.value = null;
      }
    }
    // const formData = new FormData();
    // formData.append('file', file.value);
</script>
<template>
    <el-card class="page-container">
        <template #header>
            <div class="header">
                <span>学生列表</span>
                <div class="extra">
                    <el-button type="primary">添加</el-button>
                </div>
            </div>
            <el-form inline>
            <el-form-item label="专业" style="width: 240px">
                <el-select placeholder="请选择" v-model="smajor">
                    <el-option label="软件工程" value="软件工程"></el-option>
                    <el-option label="智能科学与技术" value="智能科学与技术"></el-option>
                    <el-option label="数据科学与大数据技术" value="数据科学与大数据技术"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="姓名" style="width: 240px">
                <el-select placeholder="请选择" v-model="sname">
                    <el-option label="郭元琳" value="郭元琳"></el-option>
                    <el-option label="张姣姣"  value="张姣姣"></el-option>
                    <el-option label="代佳兰" value="代佳兰"></el-option>
                    <el-option label="方秋宇" value="方秋宇"></el-option>
                    <el-option label="王彩娣" value="王彩娣"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="stuLists">搜索</el-button>
                <el-button @click="smajor = ''; sname = '';stuLists()">重置</el-button>
            </el-form-item>
             <!--上传文件 -->
            <el-form-item>
                <input type="file" @change="handleFileChange" />
                <el-button @click="uploadFile">上传文件</el-button>
                <el-form-item v-if="uploadMessage">
                <p>上传结果：{{ uploadMessage }}</p>
                </el-form-item>
            </el-form-item>
        </el-form>
        </template>
        <el-table :data="students" style="width: 100%">
            <el-table-column label="学号" width="100" prop="sno"> </el-table-column>
            <el-table-column label="姓名" prop="sname"></el-table-column>
            <el-table-column label="性别" prop="sex"></el-table-column>
            <el-table-column label="专业" prop="smajor"></el-table-column>
            <el-table-column label="班级" prop="sclass"></el-table-column>
           
            <el-table-column label="操作" width="100">
                <template #default="{ row }">
                    <el-button :icon="Edit" circle plain type="primary"></el-button>
                    <el-button :icon="Delete" circle plain type="danger"></el-button>
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="没有数据" />
            </template>
        </el-table>
         <!-- 分页条 -->
         <el-pagination v-model:current-page="pageNum" v-model:page-size="pageSize" :page-sizes="[3, 5, 7,10]"
            layout="jumper, total, sizes, prev, pager, next" background :total="total" @size-change="onSizeChange"
            @current-change="onCurrentChange" style="margin-top: 20px; justify-content: flex-end" />

        <!-- 添加弹窗 -->
        <el-dialog v-model="dialogVisible" :title="title" width="30%">
            <el-form :model="studentModel" :rules="rules" label-width="100px" style="padding-right: 30px">
                <el-form-item label="学号" prop="sno">
                    <el-input v-model="studentModel.sno" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="sname">
                    <el-input v-model="studentModel.sname" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                    <el-input v-model="studentModel.sex" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="专业" prop="smajor">
                    <el-input v-model="studentModel.smajor" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="班级" prop="sclass">
                    <el-input v-model="studentModel.sclass" minlength="1" maxlength="15"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="title=='添加' ? addStudent() : updateStudent()"> 确认 </el-button>
                </span>
            </template>
        </el-dialog>
    </el-card>
</template>

<style lang="scss" scoped>
.page-container {
    min-height: 100%;
    box-sizing: border-box;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>