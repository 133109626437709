import request from '@/utils/request.js'
import { useTokenStore } from '@/stores/token.js'
//课程列表信息
export const courseListService = ()=>{
    //const tokenStore = useTokenStore();
    //在pinia中定义的响应式数据，都不需要value
    //return request.get("/course",{headers:{'Authorization':tokenStore.token}})
    return request.get("/course")
}
//添加课程信息
export const AddCourseService = (courseData)=>{
    return request.post('/course',courseData)
}
//修改课程信息
export const UpdateCourseService = (courseData)=>{
    return request.put('/course/update',courseData)
}
//删除课程信息
export const DeleteCourseService = (cno)=>{
    return request.delete('/course?cno=' + cno)
}