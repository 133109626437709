import request from '@/utils/request.js'
import { useTokenStore } from '@/stores/token.js'
//列表信息
export const studentsListService = ()=>{
    //const tokenStore = useTokenStore();
    //在pinia中定义的响应式数据，都不需要value
    //return request.get("/course",{headers:{'Authorization':tokenStore.token}})
    return request.get("/student")
}
//添加信息
export const AddStudentsService = (studentData)=>{
    return request.post('/student',studentData)
}
//修改信息
export const UpdateStudentsService = (studentData)=>{
    return request.put('/student/update',studentData)
}
//删除信息
export const DeleteStudentsService = (sno)=>{
    return request.delete('/student?sno='+sno)
}
//分页
export const studentService = (params)=>{
    return request.get('/student/page',{params:params})
}
//文件上传
export const fileService = (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    return request.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
