import request from '@/utils/request.js'
import { useTokenStore } from '@/stores/token.js'
//课程列表信息
export const classListService = ()=>{
    //const tokenStore = useTokenStore();
    //在pinia中定义的响应式数据，都不需要value
    //return request.get("/course",{headers:{'Authorization':tokenStore.token}})
    return request.get("/class")
}
//添加信息
export const AddClassService = (classData)=>{
    return request.post('/class',classData)
}
//修改信息
export const UpdateClassService = (classData)=>{
    return request.put('/class/update',classData)
}
//删除信息
export const DeleteClassService = (id)=>{
    return request.delete('/class?id='+id)
}