<script setup>
import {
    Edit,
    Delete
} from '@element-plus/icons-vue'
import { ref } from 'vue'
const courses = ref([
    {
        "cno": 21001,
        "cname": "人机交互与界面设计",
        "tname": "张志斌",
        "cmajor": "软件工程",
        "cclass": "软件212"
    },
    {
        "cno": 21002,
        "cname": "WEB应用系统开发",
        "tname": "文静",
        "cmajor": "软件工程",
        "cclass": "软件212"
    },
    {
        "cno": 21003,
        "cname": "软件工程实践2",
        "tname": "文静",
        "cmajor": "软件工程",
        "cclass": "软件212"
    },
    {
        "cno": 21004,
        "cname": "面向对象程序设计",
        "tname": "郭丽",
        "cmajor": "软件工程",
        "cclass": "软件212"
    }
])
//声明一个异步的函数
import { courseListService, AddCourseService, UpdateCourseService, DeleteCourseService } from '@/api/course.js'
const courseList = async () => {
    let result = await courseListService();
    courses.value = result.data;
}
//调用
courseList();
//控制弹窗
const dialogVisible = ref(false)

// //添加数据模型
const courseModel = ref({
    "cno": "",
    "cname": "",
    "tname": "",
    "cmajor": "",
    "cclass": ""
})
// //添加分类表单校验
const rules = {
    cno: [
        { required: true, message: '请输入课程编号', trigger: 'blur' },
    ],
    cname: [
        { required: true, message: '请输入课程名称', trigger: 'blur' },
    ],
    tname: [
        { required: true, message: '请输入授课老师', trigger: 'blur' },
    ],
    cmajor: [
        { required: true, message: '请输入专业', trigger: 'blur' },
    ],
    cclass: [
        { required: true, message: '请输入班级', trigger: 'blur'}
    ],
}


// //调用接口,添加表单
import { ElMessage } from 'element-plus'
const addCourse = async () => {
    //调用接口
    let result = await AddCourseService(courseModel.value);
    ElMessage.success(result.msg ? result.msg : '添加成功')

    //调用获取所有的函数
    courseList();
    dialogVisible.value = false;
}

// //定义变量,控制标题的展示
const title = ref('')

// //展示编辑弹窗
const showDialog = (row) => {
    dialogVisible.value = true; title.value = '编辑课程'
    //数据拷贝
    courseModel.value.cno = row.cno;
    courseModel.value.cname = row.cname;
    courseModel.value.tname = row.tname;
    courseModel.value.cmajor = row.cmajor;
    courseModel.value.cclass = row.cclass;
    //扩展id属性,将来需要传递给后台,完成分类的修改
    // courseModel.value.cno = row.cno
}

// //编辑课程
const updateCourse = async () => {
    //调用接口
    let result = await UpdateCourseService(courseModel.value);

    ElMessage.success(result.msg ? result.msg : '修改成功')

    //调用获取所有课程的函数
    courseList();

    //隐藏弹窗
    dialogVisible.value = false;
}

//清空模型的数据
const clearData = () => {
    courseModel.value.cno = '';
    courseModel.value.cname = '';
    courseModel.value.tname = '';
    courseModel.value.cmajor = '';
    courseModel.value.cclass = '';
}

// //删除分类
import {ElMessageBox} from 'element-plus'
const deleteCourse = (row) => {
//     //提示用户  确认框

    ElMessageBox.confirm(
        '你确认要删除该课程信息吗?',
        '温馨提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(async () => {
            //调用接口
            let result = await DeleteCourseService(row.cno);
            ElMessage({
                type: 'success',
                message: '删除成功',
            })
            //刷新列表
            courseList();
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '用户取消了删除',
            })
        })
}
</script>
<template>
    <el-card class="page-container">
        <template #header>
            <div class="header">
                <span>课程列表</span>
                <div class="extra">
                    <el-button type="primary">添加</el-button>
                </div>
            </div>
        </template>
        <el-table :data="courses" style="width: 100%">
            <el-table-column label="编号" prop="cno" width="100"> </el-table-column>
            <el-table-column label="课程名称" prop="cname"></el-table-column>
            <el-table-column label="授课教师" prop="tname"></el-table-column>
            <el-table-column label="专业" prop="cmajor"></el-table-column>
            <el-table-column label="班级" prop="cclass"></el-table-column>
            <el-table-column label="操作" width="100">
                <template #default="{ row }">
                    <el-button :icon="Edit" circle plain type="primary"></el-button>
                    <el-button :icon="Delete" circle plain type="danger"></el-button>
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="没有数据" />
            </template>
        </el-table>

        <!-- 添加课程弹窗 -->
        <el-dialog v-model="dialogVisible" :title="title" width="30%">
            <el-form :model="courseModel" :rules="rules" label-width="100px" style="padding-right: 30px">
                <el-form-item label="课程编号" prop="cno">
                    <el-input v-model="courseModel.cno" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="课程名称" prop="cname">
                    <el-input v-model="courseModel.cname" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="授课老师" prop="tname">
                    <el-input v-model="courseModel.tname" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="专业" prop="cmajor">
                    <el-input v-model="courseModel.cmajor" minlength="1" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="班级" prop="cclass">
                    <el-input v-model="courseModel.cclass" minlength="1" maxlength="15"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="title=='添加' ? addCourse() : updateCourse()"> 确认 </el-button>
                </span>
            </template>
        </el-dialog>
    </el-card>
</template>

<style lang="scss" scoped>
.page-container {
    min-height: 100%;
    box-sizing: border-box;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>