import request from '@/utils/request.js'
import { useTokenStore } from '@/stores/token.js'
//课程列表信息
export const cksListService = ()=>{
    //const tokenStore = useTokenStore();
    //在pinia中定义的响应式数据，都不需要value
    //return request.get("/course",{headers:{'Authorization':tokenStore.token}})
    return request.get("/cks")
}
//添加信息
export const AddCksService = (cksData)=>{
    return request.post('/cks',cksData)
}
//修改信息
export const UpdateCksService = (cksData)=>{
    return request.put('/cks/update',cksData)
}
//删除信息
export const DeleteCksService = (id)=>{
    return request.delete('/cks?id='+id)
}
//文章列表查询
export const cksListServices = (params)=>{
    return request.get("/cks/page",{params:params})
}