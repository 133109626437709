//导入vue-router
import { createRouter, createWebHistory } from 'vue-router'

//导入组件
import LoginVue from '@/views/Login.vue'
import LayoutVue from '@/views/Layout.vue'
import UserInfoVue from '@/views/user/UserInfo.vue'
import UserAvatarVue from '@/views/user/UserAvatar.vue'
import UserUpdatePwdVue from '@/views/user/UserUpdatePwd.vue'
import CourseListVue from '@/views/course/CourseList.vue'
import ClassListVue from '@/views/class/ClassList.vue'
import StudentsListVue from '@/views/student/StudentsList.vue';
import PersonalksListVue from '@/views/pks/PersonalksList.vue';
import CourseksListVue from '@/views/cks/CourseksList.vue';
// 学生管理
import StudentManageVue from '@/views/student/StudentManage.vue';
import StudentsList1Vue from '@/views/student/StudentsList1.vue';
import CourseList1Vue from '@/views/student/CourseList1.vue';
import PersonalksList1Vue from '@/views/student/PersonalksList1.vue';
import CourseksList1Vue from '@/views/student/CourseksList1.vue';
import ClassList1Vue from '@/views/student/ClassList1.vue';
import StuInfoVue from '@/views/student/StuInfo.vue';
import StuAvatarVue from '@/views/student/StuAvatar.vue';
import StuUpdatePwdVue from '@/views/student/StuUpdatePwd.vue';
// 教师管理
import TeacherManageVue from '@/views/teacher/TeacherManage.vue';
import CourseList2Vue from '@/views/teacher/CourseList2.vue';
import TeaInfoVue from '@/views/teacher/TeaInfo.vue';
import TeaAvatarVue from '@/views/teacher/TeaAvatar.vue';
import TeaUpdatePwdVue from '@/views/teacher/TeaUpdatePwd.vue';
//定义路由关系
const routes = [
    { path: '/login', component: LoginVue },
    { 
        path: '/', component: LayoutVue,
        redirect:"/student",
        children:[
            { path: '/user/info', component: UserInfoVue},
            { path: '/user/avatar', component: UserAvatarVue},
            { path: '/user/updatePwd', component: UserUpdatePwdVue},
            { path: '/course', component: CourseListVue},
            { path: '/class', component: ClassListVue},
            { path: '/student', component: StudentsListVue},
            { path: '/pks', component: PersonalksListVue},
            { path: '/cks', component: CourseksListVue},
        ]
     },
     { path: '/login', component: LayoutVue },
     {
        path: '/stu', component: StudentManageVue,
        redirect:"/stu/student1",
        children:[
            { path: '/stu/info', component: StuInfoVue},
            { path: '/stu/avatar', component: StuAvatarVue},
            { path: '/stu/updatePwd', component: StuUpdatePwdVue},
            { path: '/stu/student1', component: StudentsList1Vue},
            { path: '/stu/pks1', component: PersonalksList1Vue},
            { path: '/stu/cks1', component: CourseksList1Vue},
            { path: '/stu/course1', component: CourseList1Vue},
            { path: '/stu/class1', component: ClassList1Vue},
        ]
     },
     { path: '/login', component: LayoutVue },
     {
        path: '/tea', component: TeacherManageVue,
        redirect:"/tea/course2",
        children:[
            { path: '/tea/info', component: TeaInfoVue},
            { path: '/tea/avatar', component: TeaAvatarVue},
            { path: '/tea/updatePwd', component: TeaUpdatePwdVue},
            { path: '/tea/student', component: StudentsListVue},
            { path: '/tea/pks', component: PersonalksListVue},
            { path: '/tea/cks', component: CourseksListVue},
            { path: '/tea/course2', component: CourseList2Vue},
            { path: '/tea/class', component: ClassListVue},
        ]
     },
]

//创建路由器
const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

//导出路由
export default router;
